import { Factory, trait } from 'miragejs';
import faker from 'faker/locale/es';

faker.seed(1);

export default Factory.extend({
	name: () => faker.name.firstName(),
	rememberToken: () => faker.random.uuid(),
	authToken: () => faker.random.uuid(),
	userId: 'C00',
	password: '123456',
	loginErrorCount: 0,
	stateId: 'ACTIVE',
	companies: ['SM'],
	blocked: trait({
		stateId: 'BLOCKED',
	}),
});
