import { Factory, trait } from 'miragejs';
import faker from 'faker/locale/es';

export default Factory.extend({
	signatureId: () => faker.random.uuid(),
	operationType: 'transfer',
	operationDescription: 'transferencia',
	creationDate: faker.date.past(2).toISOString(),
	signatureDate: null,
	data: {
		amount: () => parseFloat(faker.finance.amount()),
		currency: 'EUR',
		origin: () => faker.random.number(9999),
		destination: () => faker.random.number(9999),
	},
	pending: trait({ status: 'pending' }),
	signed: trait({
		status: 'signed',
		signatureDate: faker.date.future(2).toISOString(),
	}),
	canceled: trait({ status: 'canceled' }),
	expired: trait({ status: 'expired' }),
	// En la espera del estado para las operaciones con más de un firmante
	unknown: trait({
		status: 'unknown',
		signatureDate: faker.date.future(2).toISOString(),
	}),
});
