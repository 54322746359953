import { Factory, trait } from 'miragejs';
import faker from 'faker/locale/es';

const types = [
	{ id: 1, name: 'Regulatorias', priority: 1 },
	{ id: 2, name: 'Comerciales', priority: 2 },
	{ id: 3, name: 'Operativas', priority: 3 },
];
// const spaces = [
// 	{ id: 1, name: 'prelogin' },
// 	{ id: 2, name: 'globalpos' },
// 	{ id: 3, name: 'banner' },
// ];
// const images = [
// 	'/news/w1cut.jpg',
// 	'/news/m1cut.jpg',
// 	'/news/w2cut.jpg',
// 	'/news/m2cut.jpg',
// 	'/news/w3cut.jpg',
// 	'/news/m3cut.jpg',
// 	'/news/m4cut.jpg',
// ];
// posible solución para no usar los textos lorem de faker
// const titles = [
// 	'¿Are you ready to buy your dreams home?',
// 	'Download your wallet app and view all your cryptocurrencies',
// 	'What are you waiting for? Buy your crytos quickly and easily',
// ];
// const bodies = [
// 	'Apply now for your mortgage easily and quickly',
// 	'So that you are always informed of the latest trends in crypto',
// ];

const items = {
	slider1: {
		// space: { id: 1, name: 'prelogin' },
		title: '¿Are you ready to buy your dreams home?',
		body: 'Apply now for your mortgage easily and quickly',
		imageURL: '/news/w3cut.jpg',
		ctaText: 'Apply',
	},
	slider2: {
		// space: { id: 1, name: 'prelogin' },
		title: 'Download your wallet app and view all your cryptocurrencies',
		body: 'So that you are always informed of the latest trends in crypto',
		imageURL: '/news/w2cut.jpg',
		ctaText: 'Download now',
	},
	slider3: {
		// space: { id: 1, name: 'prelogin' },
		title: 'What are you waiting for? Buy your crytos quickly and easily',
		body: '',
		imageURL: '/news/w1cut.jpg',
		ctaText: 'Buy now',
	},
	slider4: {
		// space: { id: 2, name: 'globalpos' },
		title: 'You have a pre-approved credit of  25,000 €',
		body: 'What are you waiting for',
		imageURL: '/news/m1cut.jpg',
		ctaText: 'Apply now',
	},
	slider5: {
		// space: { id: 2, name: 'globalpos' },
		title: 'You have a signature pending to confirm',
		body: 'Sign now and save time',
		imageURL: '/news/w3cut.jpg',
		ctaText: 'Sign now',
	},
};

const pre = ['slider1', 'slider2', 'slider3'];
const post = ['slider4', 'slider5'];

faker.seed(1);
export default Factory.extend({
	preLogin: trait({
		slide() {
			return faker.random.arrayElement(pre);
		},
		title() {
			return items[this.slide].title;
		},
		body() {
			return items[this.slide].body;
		},
		imageURL() {
			return items[this.slide].imageURL;
		},
		ctaText() {
			return items[this.slide].ctaText;
		},
		space: { id: 1, name: 'prelogin' },
	}),
	postLogin: trait({
		slide() {
			return faker.random.arrayElement(post);
		},
		title() {
			return items[this.slide].title;
		},
		body() {
			return items[this.slide].body;
		},
		imageURL() {
			return items[this.slide].imageURL;
		},
		ctaText() {
			return items[this.slide].ctaText;
		},
		space: { id: 2, name: 'globalpos' },
	}),
	type: () => faker.random.arrayElement(types),
	creationDate: () => faker.date.past().toISOString(),
	// title: () => faker.lorem.sentence(),
	// title: () => faker.random.arrayElement(titles),
	// body: () => faker.lorem.sentence(),
	// body: () => faker.random.arrayElement(bodies),
	feedback: () => faker.random.arrayElement([-1, 0, 1]),
	impressions: 0,
	// ctaText: 'Pulse aquí',
	ctaAction: 'open_internal',
	ctaRedirect: () => faker.random.arrayElement(['#', null]),
});
