import { render, staticRenderFns } from "./w-notification.vue?vue&type=template&id=17f0ea70&scoped=true&"
import script from "./w-notification.vue?vue&type=script&lang=js&"
export * from "./w-notification.vue?vue&type=script&lang=js&"
import style0 from "./w-notification.vue?vue&type=style&index=0&id=17f0ea70&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "17f0ea70",
  null
  
)

component.options.__file = "w-notification.vue"
export default component.exports