import { Response } from 'miragejs';
import { typesById, typesByTitle } from '@modules/products/product-types';
import { subtypesById } from '@modules/products/product-subtypes';
import { intervenersByTitle } from '@modules/products/product-interveners';
import faker from 'faker/locale/es';
import accountMock from './mock-product-account';
import cardMock from './mock-product-card';
import creditMock from './mock-product-credit';
import endorsementMock from './mock-product-endorsement';
import depositMock from './mock-product-deposit';
import pensionPlanMock from './mock-product-pension-plan';
import loanMock from './mock-product-loan';
import equitiesMock from './mock-product-equities';
import fundMock from './mock-product-fund';
import fundAssetMock from './mock-product-fund-asset';
import subscriptionMock from './mock-subscription';
import managedPortfolioMock from './mock-managed-portfolio';
import cryptoAccountMock from './mock-product-crypto-account';

const mocks = {
	'account': accountMock,
	'card': cardMock,
	'credit': creditMock,
	'endorsement': endorsementMock,
	'deposit': depositMock,
	'pension-plan': pensionPlanMock,
	'loan': loanMock,
	'equities': equitiesMock,
	'fund': fundMock,
	'fund-asset': fundAssetMock,
	'subscription': subscriptionMock,
	'managed-portfolio': managedPortfolioMock,
	'crypto-account': cryptoAccountMock,
};

export default async function(schema, request) {
	const session = schema.sessions.find(request.requestHeaders.uuid);
	const byService = request?.queryParams?.byService;
	const { user } = session;

	if (byService) {
		const isAccount = ({ productType }) => productType.id === typesByTitle.account;
		const isHolder = ({ relationType }) => relationType.id === intervenersByTitle.holder;

		const models = user.products.models.filter(isAccount).filter(isHolder);

		const response = {
			result: { code: '200', info: 'OK' },
			data: models,
		};

		return new Response(200, {}, response);
	}

	if (request?.params?.productId) {
		const product = schema.products.find(request.params.productId);
		const type = typesById[product.productType.id];
		let mockProduct = mocks[type];

		if (request?.params?.assetId) {
			mockProduct = mocks[`${type}-asset`];
			return mockProduct(schema, request);
		}

		return mockProduct(schema, request);
	}

	const models = JSON.parse(JSON.stringify(user.products.models));
	const products = models.map((product) => ({
		id: product.id,
		name: product.name,
		alias: product.alias,
		productType: product.productType,
		productSubtype: product.productSubtype,
		relationType: product.relationType,
		balance: product.balance,
		postedBalance: product.postedBalance,
		limitAmount: product.limitAmount,
		productNumber: product.productNumber
			? { format: product.productNumber.format, value: product.productNumber.value.substr(-4) }
			: null,
	}));

	const createResources = (product) => {
		if (!product.movements?.length) {
			const randomNumber = faker.random.number({ min: 0, max: 200 });
			const productSubtype = subtypesById[product.productSubtype.id];
			const VIP_PREFIX = 'vip-';
			const CURRENCY_PREFIX = 'currency-';
			const subtype = productSubtype
				// Quita el vip- del subtype para obtener el subtype real de las vip
				.split(VIP_PREFIX)
				.reverse()[0]
				// Quita el currency- del subtype para obtener el subtype real de las divisas
				.split(CURRENCY_PREFIX)
				.reverse()[0];

			if (['managed-portfolio'].includes(subtype)) {
				return product.products.models.forEach(createResources);
			}

			if (['smartfi-equities', 'endorsement', 'endorsement-line'].includes(subtype)) {
				return;
			}

			const movements = this.createList('movement', randomNumber, subtype);

			movements.sort(({ valueDate: a }, { valueDate: b }) => new Date(b) - new Date(a));
			product.update({ movements });

			if (
				['securities-account', 'advised-fund', 'commercialized-fund', 'delegated-fund'].includes(
					subtype
				)
			) {
				const assets = this.createList('asset', faker.random.number({ min: 4, max: 10 }), subtype);

				product.update({ assets });
			}
		}
	};

	user.products.models.forEach(createResources);

	const response = {
		result: {
			code: '200',
			info: 'OK',
		},
		data: products,
	};

	return new Response(200, {}, response);
}
