import { Response } from 'miragejs';
import SignedOperation from './mock-signed-operation';

export default async function(schema, request) {
	const { user } = schema.sessions.find(request.requestHeaders.uuid);
	const { signatures } = user;
	let response;

	if (request.method === 'GET') {
		response = { signatures: signatures.models };

		if (request.queryParams.status) {
			const types = request.queryParams.status.split('|');
			const operations = signatures.models.filter(({ status }) => types.includes(status));
			response = { signatures: operations };
		}

		return new Response(200, {}, response);
	}

	const id = request?.params?.signatureId;
	const { method } = request;

	if (!id) {
		return new Response(400, {}, {});
	}

	const operation = signatures.models.find(({ signatureId }) => signatureId === id);

	if (method === 'PUT' || method === 'PATCH') {
		operation.status = 'signed';
		operation.signatureDate = new Date();
	}

	if (method === 'DELETE') {
		operation.status = 'canceled';
	}

	const handler = () => {
		signatures.save();
		return new Response(200, {}, { signatureId: id });
	};

	return new SignedOperation(schema, handler);
}
