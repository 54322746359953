export default {
	namespaced: true,

	state() {
		return { signatures: null };
	},

	actions: {
		get({ dispatch }, { status = '' }) {
			const type = status;

			return dispatch(
				'service/request',
				{
					service: {
						request: {
							url: '/signature',
							method: 'GET',
						},
					},
					queryParams: { status: type },
				},
				{ root: true }
			).then(({ data: { signatures } }) => signatures);
		},

		sign({ dispatch }, signatureId) {
			const url = `/signature/${signatureId}`;

			return dispatch(
				'service/request',
				{
					service: {
						request: { url, method: 'PUT' },
					},
				},
				{ root: true }
			);
		},

		delete({ dispatch }, signatureId) {
			const url = `/signature/${signatureId}`;

			return dispatch(
				'service/request',
				{
					service: {
						request: { url, method: 'DELETE' },
					},
				},
				{ root: true }
			);
		},

		complete({ dispatch }, signatureId) {
			const url = `/signature/${signatureId}`;

			return dispatch(
				'service/request',
				{
					service: {
						request: { url, method: 'PATCH' },
					},
				},
				{ root: true }
			).then(() => {});
		},
	},
};
