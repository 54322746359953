import faker from 'faker/locale/es';

export default class {
	constructor() {
		this.ids = new Set();
	}

	fetch() {
		let uuid = faker.random.uuid();
		while (this.ids.has(uuid)) {
			uuid = faker.random.uuid();
		}

		this.ids.add(uuid);

		return uuid;
	}

	set(uuid) {
		if (this.ids.has(uuid)) {
			throw new Error(`Attempting to use the ID ${uuid}, but it's already been used`);
		}

		this.ids.add(uuid);
	}

	reset() {
		this.ids.clear();
	}
}
