const SET_CONNECTED_CONTRACT = 'SET_CONNECTED_CONTRACT';

export default {
	namespaced: true,

	state() {
		return {
			connectedContract: null,
		};
	},

	mutations: {
		[SET_CONNECTED_CONTRACT](state, value) {
			state.connectedContract = value;
		},
	},

	actions: {
		changePassword({ dispatch }, password) {
			return dispatch(
				'service/request',
				{
					service: {
						request: {
							url: '/current/user/password',
							method: 'PUT',
						},
					},
					payload: { password },
				},
				{ root: true }
			);
		},

		getPersonalDetails({ dispatch }) {
			return dispatch(
				'service/request',
				{
					service: {
						request: {
							url: '/current/user/',
							method: 'GET',
						},
					},
				},
				{ root: true }
			);
		},

		ssoLogin({ dispatch }) {
			return dispatch(
				'service/request',
				{
					service: {
						request: {
							url: '/current/user/sso-login',
							method: 'GET',
						},
					},
				},
				{ root: true }
			);
		},

		ssoBolsaSmartfi({ dispatch }) {
			return dispatch(
				'service/request',
				{
					service: {
						request: {
							url: '/current/user/bolsa-smartfi-session',
							method: 'GET',
						},
					},
				},
				{ root: true }
			);
		},

		removeConnectedContract({ commit }) {
			commit(SET_CONNECTED_CONTRACT, null);
		},

		getContracts({ dispatch }) {
			return dispatch(
				'service/request',
				{
					service: {
						request: {
							url: '/contracts',
							method: 'GET',
						},
					},
				},
				{ root: true }
			).then(({ data }) => data);
		},

		setContract({ commit, dispatch, state }, contract) {
			const { connectedContract } = state;

			if (connectedContract?.id === contract?.id) {
				return;
			}

			const { id } = contract;
			const url = `/contracts/${id}`;

			return dispatch(
				'service/request',
				{
					service: {
						request: {
							url,
							method: 'PATCH',
						},
					},
				},
				{ root: true }
			)
				.then(({ data }) => {
					commit(SET_CONNECTED_CONTRACT, data);
					return data;
				})
				.catch(async () => {
					const component = await import(
						/* webpackChunkName: "chunk-m-errors" */ '@modals/m-something-wrong'
					);

					return dispatch('modal/open', { component, props: { modal: true } }, { root: true });
				});
		},
	},
};
