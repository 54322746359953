import config from '@local-router';

export default [
	...config.routes,
	{
		path: '*',
		redirect: { name: 'login' },
	},
	{
		path: '/entry',
		name: 'entry',
	},
	{
		path: '/',
		name: 'login',
		components: {
			default: () =>
				import(/* webpackChunkName: "v-login", webpackPrefetch: true */ '@views/v-login'),
		},
	},
	{
		path: '/main/',
		name: 'main',
		props: { default: true },
		components: {
			default: () => import(/* webpackChunkName: "v-main" */ '@views/v-main'),
		},
		children: [
			{
				path: 'global',
				name: 'global',
				props: { primary: true },
				meta: { fullWidth: true },
				components: {
					primary: () => import(/* webpackChunkName: "v-global" */ '@views/v-global'),
				},
			},
			{
				path: 'linea-smartfi',
				name: 'linea-smartfi',
				props: { primary: true },
				meta: { fullWidth: true, transition: 'fade' },
				components: {
					primary: () => import(/* webpackChunkName: "v-linea-smartfi" */ '@views/v-linea-smartfi'),
				},
			},
			{
				path: 'bolsa-smartfi',
				name: 'bolsa-smartfi',
				props: { primary: true },
				meta: { fullWidth: true, transition: 'fade' },
				components: {
					primary: () => import(/* webpackChunkName: "v-bolsa-smartfi" */ '@views/v-bolsa-smartfi'),
				},
			},
			{
				path: 'tax-info',
				name: 'tax-info',
				props: { primary: { action: 'InformacionFiscal' } },
				meta: { fullWidth: true, transition: 'fade' },
				components: {
					primary: () => import(/* webpackChunkName: "v-linea-smartfi" */ '@views/v-linea-smartfi'),
				},
			},
			{
				path: 'customer-service',
				name: 'customer-service',
				props: { primary: true },
				meta: { fullPage: true },
				components: {
					primary: () =>
						import(/* webpackChunkName: "v-customer-service" */ '@views/v-customer-service'),
				},
			},
			{
				path: 'feedback',
				name: 'feedback',
				props: { primary: true },
				meta: { fullPage: true },
				components: {
					primary: () => import(/* webpackChunkName: "v-feedback" */ '@views/v-feedback'),
				},
			},
			{
				path: 'product/:familyId/:productId/movement/:movementId',
				name: 'movement',
				props: { primary: true, secondary: true },
				components: {
					primary: () => import(/* webpackChunkName: "v-global" */ '@views/v-global'),
					secondary: () => import(/* webpackChunkName: "v-movement" */ '@views/v-movement'),
				},
			},
			{
				path: 'product/:familyId/:productId',
				name: 'product',
				props: { primary: true, secondary: true },
				components: {
					primary: () => import(/* webpackChunkName: "v-global" */ '@views/v-global'),
					secondary: () => import(/* webpackChunkName: "v-product" */ '@views/v-product'),
				},
			},
			{
				path: 'product/:familyId/:productId/imposition/:movementId',
				name: 'imposition',
				props: { primary: true, secondary: true },
				components: {
					primary: () => import(/* webpackChunkName: "v-global" */ '@views/v-global'),
					secondary: () => import(/* webpackChunkName: "v-imposition" */ '@views/v-imposition'),
				},
			},
			{
				path: 'product/:familyId/:productId/asset/:assetId',
				name: 'asset',
				props: { primary: true, secondary: true },
				components: {
					primary: () => import(/* webpackChunkName: "v-global" */ '@views/v-global'),
					secondary: () => import(/* webpackChunkName: "v-asset" */ '@views/v-asset'),
				},
			},
			{
				path: 'product/:familyId/search-movements/:productId/',
				name: 'search-movements',
				props: { primary: true, secondary: true },
				components: {
					primary: () => import(/* webpackChunkName: "v-global" */ '@views/v-global'),
					secondary: () => import(/* webpackChunkName: "v-movement" */ '@views/v-search-movements'),
				},
			},
			{
				path: 'product-detail/:familyId/:productId',
				name: 'product-detail',
				props: { primary: true, secondary: true },
				meta: { transition: 'fade' },
				components: {
					primary: () => import(/* webpackChunkName: "v-global" */ '@views/v-global'),
					secondary: () =>
						import(/* webpackChunkName: "v-product-detail" */ '@views/v-product-detail'),
				},
			},
			{
				path: 'product-group/:familyId',
				name: 'product-group',
				props: { primary: true, secondary: true },
				components: {
					primary: () => import(/* webpackChunkName: "v-global" */ '@views/v-global'),
					secondary: () =>
						import(/* webpackChunkName: "v-product-group" */ '@views/v-product-group'),
				},
			},
			{
				path: 'vip/:familyId',
				name: 'vip',
				props: { primary: true, secondary: true },
				components: {
					primary: () => import(/* webpackChunkName: "v-global" */ '@views/v-global'),
					secondary: () => import(/* webpackChunkName: "v-vip" */ '@views/v-vip'),
				},
			},
			{
				path: 'managed/:familyId/:productId',
				name: 'managed-portfolio',
				props: { primary: true, secondary: true },
				components: {
					primary: () => import(/* webpackChunkName: "v-global" */ '@views/v-global'),
					secondary: () => import(/* webpackChunkName: "v-product" */ '@views/v-product'),
				},
			},
			{
				path: 'vip-services/:familyId',
				name: 'vip-services',
				props: { primary: true, secondary: true },
				components: {
					primary: () => import(/* webpackChunkName: "v-global" */ '@views/v-global'),
					secondary: () => import(/* webpackChunkName: "v-vip-services" */ '@views/v-vip-services'),
				},
			},
			{
				path: 'amortization-table/:productId',
				name: 'amortization-table',
				props: { primary: true, secondary: true },
				components: {
					primary: () => import(/* webpackChunkName: "v-global" */ '@views/v-global'),
					secondary: () =>
						import(/* webpackChunkName: "v-amortization-table" */ '@views/v-amortization-table'),
				},
			},
			{
				path: 'receipts-table/:productId',
				name: 'receipts-table',
				props: { primary: true, secondary: true },
				components: {
					primary: () => import(/* webpackChunkName: "v-global" */ '@views/v-global'),
					secondary: () =>
						import(/* webpackChunkName: "v-receipts-table" */ '@views/v-receipts-table'),
				},
			},
			{
				path: 'personal-area',
				name: 'personal-area',
				props: { primary: true },
				components: {
					primary: () => import(/* webpackChunkName: "v-personal-area" */ '@views/v-personal-area'),
				},
			},
			{
				path: 'personal-area/personal-details',
				name: 'personal-details',
				props: { primary: true, secondary: true },
				meta: { transition: 'fade' },
				components: {
					primary: () => import(/* webpackChunkName: "v-personal-area" */ '@views/v-personal-area'),
					secondary: () =>
						import(/* webpackChunkName: "v-personal-details" */ '@views/v-personal-details'),
				},
			},
			{
				path: 'change-password',
				name: 'change-password',
				props: { primary: true, secondary: true },
				components: {
					primary: () => import(/* webpackChunkName: "v-personal-area" */ '@views/v-personal-area'),
					secondary: () =>
						import(/* webpackChunkName: "v-change-password" */ '@views/v-change-password'),
				},
			},
			{
				path: 'language',
				name: 'language',
				props: { primary: true, secondary: true },
				components: {
					primary: () => import(/* webpackChunkName: "v-personal-area" */ '@views/v-personal-area'),
					secondary: () => import(/* webpackChunkName: "v-language" */ '@views/v-language'),
				},
			},
			{
				path: 'transfers',
				name: 'transfers',
				props: { primary: true },
				components: {
					primary: () => import(/* webpackChunkName: "v-transfers" */ '@views/v-transfers'),
				},
			},
			{
				path: 'transfer/:action/:type?/:transferId?',
				name: 'transfer',
				props: { primary: true, secondary: true },
				components: {
					primary: () => import(/* webpackChunkName: "v-transfers" */ '@views/v-transfers'),
					secondary: () => import(/* webpackChunkName: "v-transfer" */ '@views/v-transfer'),
				},
			},
			{
				path: 'my-transfers/:productId',
				name: 'my-transfers',
				props: { primary: true, secondary: true },
				components: {
					primary: () => import(/* webpackChunkName: "v-transfers" */ '@views/v-transfers'),
					secondary: () => import(/* webpackChunkName: "v-my-transfers" */ '@views/v-my-transfers'),
				},
			},
			{
				path: 'transfer-detail/:type/:transferId',
				name: 'transfer-detail',
				props: { primary: true, secondary: true },
				components: {
					primary: () => import(/* webpackChunkName: "v-transfers" */ '@views/v-transfers'),
					secondary: () =>
						import(/* webpackChunkName: "v-transfer-detail" */ '@views/v-transfer-detail'),
				},
			},
			{
				path: 'bizum',
				name: 'bizum',
				props: { primary: true, secondary: true },
				components: {
					primary: () => import(/* webpackChunkName: "v-transfers" */ '@views/v-transfers'),
					secondary: () => import(/* webpackChunkName: "v-bizum" */ '@views/v-bizum'),
				},
			},
			{
				path: 'bizum/welcome',
				name: 'bizum-welcome',
				props: { primary: true, secondary: true },
				components: {
					primary: () => import(/* webpackChunkName: "v-transfers" */ '@views/v-transfers'),
					secondary: () =>
						import(/* webpackChunkName: "v-bizum-welcome" */ '@views/v-bizum-welcome'),
				},
			},
			{
				path: 'bizum/register/:productId',
				name: 'bizum-register',
				props: { primary: true, secondary: true },
				components: {
					primary: () => import(/* webpackChunkName: "v-transfers" */ '@views/v-transfers'),
					secondary: () =>
						import(/* webpackChunkName: "v-bizum-register" */ '@views/v-bizum-register'),
				},
			},
			{
				path: 'bizum/dashboard/:type?',
				name: 'bizum-dashboard',
				props: { primary: true, secondary: true },
				components: {
					primary: () => import(/* webpackChunkName: "v-transfers" */ '@views/v-transfers'),
					secondary: () =>
						import(/* webpackChunkName: "v-bizum-dashboard" */ '@views/v-bizum-dashboard'),
				},
			},
			{
				path: 'bizum/details',
				name: 'bizum-details',
				props: { primary: true, secondary: true },
				meta: { transition: 'fade' },
				components: {
					primary: () => import(/* webpackChunkName: "v-transfers" */ '@views/v-transfers'),
					secondary: () =>
						import(/* webpackChunkName: "v-bizum-details" */ '@views/v-bizum-details'),
				},
			},
			{
				path: 'communications/:type?',
				name: 'communications',
				props: { primary: true, secondary: true },
				components: {
					primary: () =>
						import(/* webpackChunkName: "v-communications" */ '@views/v-communications'),
				},
			},
			{
				path: 'communications/:type/:messageId',
				name: 'communication-detail',
				props: { primary: true, secondary: true },
				components: {
					primary: () =>
						import(/* webpackChunkName: "v-communications" */ '@views/v-communications'),
					secondary: () =>
						import(
							/* webpackChunkName: "v-communication-detail" */ '@views/v-communication-detail'
						),
				},
			},
			{
				path: 'signatures/:type?',
				name: 'signatures',
				props: { primary: true, secondary: true },
				components: {
					primary: () => import(/* webpackChunkName: "v-global" */ '@views/v-global'),
					secondary: () => import(/* webpackChunkName: "v-signatures" */ '@views/v-signatures'),
				},
			},
			{
				path: 'signatures/:type/:signatureId',
				name: 'signature-detail',
				props: { primary: true, secondary: true },
				components: {
					primary: () => import(/* webpackChunkName: "v-global" */ '@views/v-global'),
					secondary: () =>
						import(/* webpackChunkName: "v-signature-detail" */ '@views/v-signature-detail'),
				},
			},
		],
	},
];
