import Vue from 'vue';
import { getCLS, getFID, getLCP, getFCP, getTTFB } from 'web-vitals';
import i18n from '@locales/setup';
import store from '@store';
import bugsnagClient from '@plugins/bugsnag';
import Modules from '@plugins/modules';
import ProductNumber from '@plugins/productNumber';
import { makeServer, makeServerForCypress } from '@plugins/server';
import router from './router';
import App from './app.vue';
import '@directives';

if (process.env.VUE_APP_ENDPOINT_MODE === 'mck') {
	makeServer();
}

Vue.config.productionTip = process.env.NODE_ENV === 'production';

Vue.use(Modules);
Vue.use(ProductNumber);

// If running inside Cypress...
if (window.Cypress) {
	// Ensure tests fail when Vue emits an error.
	Vue.config.errorHandler = window.Cypress.cy.onUncaughtException;

	makeServerForCypress();
}

// Envía las métricas de rendimiento a google analytics.
if (window.dataLayer) {
	const sendToGoogleAnalytics = ({ name, delta, value, id }) => {
		window.dataLayer.push({
			event: 'coreWebVitals',
			webVitalsMeasurement: {
				name,
				id,
				value,
				delta,
				valueRounded: Math.round(name === 'CLS' ? value * 1000 : value),
				deltaRounded: Math.round(name === 'CLS' ? delta * 1000 : delta),
			},
		});
	};

	getCLS(sendToGoogleAnalytics);
	getFID(sendToGoogleAnalytics);
	getLCP(sendToGoogleAnalytics);
	getFCP(sendToGoogleAnalytics);
	getTTFB(sendToGoogleAnalytics);
}

const instance = new Vue({
	i18n,
	router,
	store,
	render: (h) => h(App),
	created() {
		this.$store.dispatch('bugsnag/install', bugsnagClient);

		window.addEventListener('message', ({ data, source, origin }) => {
			if (data?.name === 'bridge-store-dispatch') {
				return this.$store
					.dispatch(data.action, data.payload)
					.then((response) => {
						window.postMessage(
							{
								name: 'bridge-store-response',
								request: { action: data.action, payload: data.payload },
								response: JSON.parse(JSON.stringify(response)),
							},
							'*'
						);
					})
					.catch((error) => {
						window.postMessage(
							{
								name: 'bridge-store-error',
								request: { action: data.action, payload: data.payload },
								response: error,
							},
							'*'
						);
					});
			}

			if (data?.name === 'login') {
				return this.$store.dispatch('authn/authorizeLogin', data).then(() => {
					window.dispatchEvent(new Event('ready-for-action'));
					this.$router.push({ name: 'global' });
					source.postMessage({ name: 'login-success' }, origin);
				});
			}
		});

		if (window.parent) {
			window.parent.postMessage({ name: 'vuesoma-is-ready' }, '*');
		}

		window.postMessage({ name: 'vuesoma-is-ready' }, '*');
	},
}).$mount('#vuesoma');

window.__app__ = instance; // eslint-disable-line no-underscore-dangle
