import { render, staticRenderFns } from "./m-expired-session.vue?vue&type=template&id=5e4f142b&scoped=true&"
import script from "./m-expired-session.vue?vue&type=script&lang=js&"
export * from "./m-expired-session.vue?vue&type=script&lang=js&"
import style0 from "./m-expired-session.vue?vue&type=style&index=0&id=5e4f142b&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5e4f142b",
  null
  
)

component.options.__file = "m-expired-session.vue"
export default component.exports