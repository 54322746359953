const def = {
	'01': 'checking-account',
	'02': 'account',
	'03': 'currency-account',
	'04': 'junior-account',
	'05': 'support-account',
	'06': 'debit-card',
	'07': 'credit-card',
	'08': 'term-deposit',
	'09': 'demand-deposit',
	'10': 'currency-deposit',
	'11': 'advised-fund',
	'12': 'commercialized-fund',
	'13': 'delegated-fund',
	'14': 'pension-plan',
	'15': 'smartfi-equities',
	'16': 'securities-account',
	'17': 'loan-var',
	'18': 'loan-fixed',
	'19': 'loan-mix',
	'20': 'endorsement',
	'21': 'endorsement-line',
	'22': 'tpv',
	'23': 'guarantee-policy-fixed',
	'24': 'guarantee-policy-var',
	'25': 'discount-line',
	'26': 'vip-account',
	'27': 'managed-portfolio',
	'28': 'vip-debit-card',
	'29': 'vip-credit-card',
	'30': 'vip-deposit',
	'31': 'pending-movements',
	'32': 'etherium',
	'33': 'bitcoin',
	'34': 'xrp',
	'm-01': 'managed-account',
	'm-03': 'managed-currency-account',
	'm-06': 'managed-debit-card',
	'm-07': 'managed-credit-card',
	'm-08': 'managed-deposit',
	'm-10': 'managed-currency-deposit',
	'm-13': 'managed-fund',
	'm-16': 'managed-equities',
};

export const subtypesById = def;
export const subtypesByTitle = Object.fromEntries(Object.entries(def).map((a) => a.reverse()));
