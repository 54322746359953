<template>
	<l-modal>
		<template v-slot:icon>
			<c-icon class="icon" src="@icons/noInternet" />
		</template>

		<template v-slot:header>
			{{ $t('NO_INTERNET.INFO.TITLE') }}
		</template>

		<article>
			<p>
				{{ $t('NO_INTERNET.INFO.DESC') }}
			</p>

			<div class="m-no-internet__contact-detail">
				<p>
					<c-icon class="m-no-internet__contact-icon" src="@icons/phone" />
					{{ $t('FORGOTTEN_PASSWORD.TEL_BANK') }}
				</p>
				<a
					class="m-no-internet__telephone-number text-fixed-m-bold"
					:href="`tel:${$store.state.app.telephone}`"
					v-a11y-tel="$store.state.app.telephone"
				>
					{{ $store.state.app.telephone }}
				</a>
				<p>{{ $t('NO_INTERNET.CONTACT_US') }}</p>
			</div>
		</article>

		<template v-slot:buttons>
			<c-button data-testid="accept" raised confirm @click="$emit('close')">
				{{ $t('OK') }}
			</c-button>
		</template>
	</l-modal>
</template>

<script>
import LModal from '@layouts/l-modal';
import CButton from '@components/c-button';
import CIcon from '@components/c-icon';

export default {
	name: 'm-no-internet',

	components: { LModal, CButton, CIcon },
};
</script>

<style lang="scss" scoped>
article {
	display: flex;
	flex-direction: column;
	min-height: 120px;
	margin: 0 auto;
}

.m-no-internet__contact-detail {
	margin-top: 30px;
	padding: 30px;
	border: 1px solid RGB(var(--color-surface-dark));
}

.m-no-internet__contact-detail:not(:last-of-type)::after {
	content: '';
	display: block;
	width: 125px;
	height: 2px;
	background: RGB(var(--color-primary));
	margin: 32px auto 0;
}

.m-no-internet__contact-detail p {
	margin-bottom: 10px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.m-no-internet__contact-icon {
	margin-right: 10px;
	font-size: inherit;
}

.m-no-internet__telephone-number {
	width: 100%;
	line-height: 2;
	display: inline-block;
	outline: 0;
	margin-bottom: 10px;
	border-bottom: 3px solid RGB(var(--color-secondary));
	padding-bottom: 10px;
}
</style>
