import { Factory, trait } from 'miragejs';
import faker from 'faker/locale/es';

faker.seed(1);

const balanceGenerator = {
	balance: { amount: 0, currency: { id: 'EUR', code: '978' } },
};

const fundFields = {
	reason: () => 'Suscripción',
	effectiveValue: {
		amount: () => parseFloat(faker.finance.amount()),
		currency: { id: 'EUR', code: '978' },
	},
	isin: () => faker.finance.bic(),
	name: () => faker.company.companyName(),
	liquidationValue: {
		amount: () => parseFloat(faker.finance.amount()),
		currency: { id: 'EUR', code: '978' },
	},
	unityQuantity: () => faker.random.number({ min: 1, max: 100000 }),
};

export default Factory.extend({
	'reason': () => faker.lorem.words(4),
	'amount': {
		amount: () => parseFloat(faker.finance.amount()) * -1,
		currency: { id: 'EUR', code: '978' },
	},
	'valueDate': () => {
		const randomNumber = faker.random.number({ min: 0, max: 120 });

		return new Date(new Date().setDate(new Date().getDate() - randomNumber)).toISOString();
	},
	'operationDate': function operationDate() {
		return this.valueDate;
	},
	'type': {
		id: 'TRPE',
		name: faker.lorem.words(2),
	},

	'checking-account': trait(balanceGenerator),
	'account': trait(balanceGenerator),
	'currency-account': trait({}),
	'junior-account': trait(balanceGenerator),
	'support-account': trait(balanceGenerator),
	'debit-card': trait({}),
	'etherium': trait({}),
	'bitcoin': trait({}),
	'xrp': trait({}),
	'credit-card': trait({}),
	'term-deposit': trait({
		amount: {
			amount: () => parseFloat(faker.finance.amount()),
			currency: { id: 'EUR', code: '978' },
		},
		state: {
			id: '00',
			name: 'Cancelada',
		},
	}),
	'deposit': trait({
		amount: {
			amount: () => parseFloat(faker.finance.amount()),
			currency: { id: 'EUR', code: '978' },
		},
		state: {
			id: '00',
			name: 'Cancelada',
		},
	}),
	'demand-deposit': trait(balanceGenerator),
	'currency-deposit': trait({}),
	'advised-fund': trait(fundFields),
	'commercialized-fund': trait(fundFields),
	'delegated-fund': trait(fundFields),
	'pension-plan': trait({
		unityQuantity: faker.random.number({ min: 1, max: 100000 }),
		unityValue: {
			amount: () => parseFloat(faker.finance.amount(1, 10000, 9)),
			currency: { id: 'EUR', code: '978' },
		},
	}),
	'shares': trait({}),
	'securities-account': trait({
		reason: () => faker.finance.transactionType(),
		unityQuantity: () => faker.random.number({ min: 1, max: 100000 }),
		isin: () => faker.finance.bic(),
		name: () => faker.company.companyName(),
		price: {
			amount: () => parseFloat(faker.finance.amount()),
			currency: { id: 'EUR', code: '978' },
		},
		liquidAmount: {
			amount: () => parseFloat(faker.finance.amount()),
			currency: { id: 'EUR', code: '978' },
		},
		comisionsRetentions: {
			amount: () => parseFloat(faker.finance.amount()),
			currency: { id: 'EUR', code: '978' },
		},
	}),
	'loan-var': trait({
		type: () => faker.lorem.words(4),
	}),
	'loan-fixed': trait({
		type: () => faker.lorem.words(4),
	}),
	'loan-mix': trait({
		type: () => faker.lorem.words(4),
	}),
	'tpv': trait({}),
	'guarantee-policy-fixed': trait(balanceGenerator),
	'guarantee-policy-var': trait(balanceGenerator),
	'discount-line': trait({}),
	'subscription': trait({}),
	'pending-movements': trait({
		originFund: { id: () => faker.finance.bic(), name: () => faker.finance.bic() },
	}),
});
