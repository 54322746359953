// La sesión ha expirado y necesita una nueva clave par.
export const UUID_EXPIRED = 'C401000101';

// Error genérico de credenciales inválidas. No cuenta como intento.
export const USER_INVALID_CRED = 'C4010000';

// Usuario y/o contraseña incorrectos. Cuenta como intento.
export const USER_NOT_FOUND = 'C401000201';

// El usuario será temporalmente bloqueado.
export const USER_WILL_BE_TEMP_BLOCKED = 'C401000202';

// El usuario ha sido temporalmente bloqueado.
export const USER_WAS_TEMP_BLOCKED = 'C401000203';

// El usuario será bloqueado.
export const USER_WILL_BE_PERMANENTLY_BLOCKED = 'C401000204';

// El usuario ha sido bloqueado.
export const USER_WAS_PERMANENTLY_BLOCKED = 'C401000205';

// El token de usuario recordado no es válido o lo era pero ha caducado.
export const REMEMBER_TOKEN_INVALID = 'C401000207';

// La petición no es válida porque requiere subida de nivel se la sesión.
export const SCA_REQUIRED = 'C403000003';

// Se requiere una clave única para poder continuar.
// La clave única se ha enviado al teléfono del cliente.
export const OTP_REQUIRED = 'C403000001';

// La clave única enviada no es válida.
export const OTP_INVALID = 'C403000101';

// El usuario ha pedido una nueva clave única
export const OTP_RENEWED = 'C403000103';

// La clave única enviada ha expirado (expira en dos minutos).
export const OTP_EXPIRED = 'C403000102';

// Error al validar la clave única. Este código suele salir al tercer intento.
// Probablemente el usuario ha sido bloqueado.
export const OTP_ERROR = 'C403000002';

// El servidor no ha respondido en el tiempo establecido.
export const REQUEST_TIMEOUT = 'ECONNABORTED';
