import { Response } from 'miragejs';

export default async function() {
	const response = `
<div>
	<p>Sociedad de Procedimientos de Pago, S.L. (“SdPP”), con domicilio en Madrid, calle Francisco Sancha, número 12, y con N.I.F. número B-87599478, inscrita en el Registro Mercantil de Madrid, al tomo 35.043, folio 30, hoja número M-630249, inscripción 1ª, es la titular del portar accesible en la dirección URL <u><a href="https://www.bizum.es/" target="_blank" rel="noopener noreferrer">https://www.bizum.es</a></u>.</p>
	<p>El acceso al portal de Bizum y a la información relativa a cualesquiera de los productos y servicios contenidos en el mismo, confiere la condición de Usuario y comporta la aceptación de las condiciones previstas en el presente Aviso Legal. Por ello le recomendamos que lea atentamente su contenido si usted desea acceder y hacer uso de la información y los servicios ofrecidos desde el portal de Bizum.</p>
	<h3><strong>Validez de la información</strong></h3>
	<p>La información y las condiciones contenidas en estas páginas son las vigentes desde la fecha de su última actualización. SdPP se reserva el derecho a modificarlas en cualquier momento, en cuyo caso entrarán en vigor desde su publicación y serán aplicables a todos los usuarios del portal desde esa fecha. Los contenidos del portal de Bizum, en especial las referencias informativas y publicitarias, salvo que expresamente se indique lo contrario, no constituyen oferta vinculante. SdPP se reserva el derecho a introducir modificaciones u omitir parcial o totalmente los actuales contenidos del portal de Bizum cuando lo considere oportuno, así como impedir o restringir el acceso de forma temporal o permanente.</p>
	<h3><strong>Establecimiento de enlaces a terceros</strong></h3>
	<p>El portal de Bizum puede poner a disposición de sus Usuarios dispositivos técnicos de enlace, que seleccionados por el Usuario le redireccionarán a un sitio web distinto de Bizum.</p>
	<p>En esta situación, SdPP, como prestador de servicios de la sociedad de la información, de acuerdo con el artículo 17 de la Ley 34/2002, de 11 de julio, de Servicios de la Sociedad de la Información y el Comercio Electrónico, quedará exento de responsabilidad por la información que contribuye a hacer accesible a través de los enlaces publicados en su sitio web, salvo en caso de que tenga conocimiento del contenido ilícito de los enlaces publicados en su web y no hubiera procedido a retirarlos.</p>
	<p>Los Usuarios que tengan constancia de que alguno de los enlaces publicados en el sitio web redirige a un contenido inadecuado, pueden comunicárselo a SdPP en la dirección de su domicilio social indicada al comienzo de este Aviso Legal, indicando nombre, dirección (postal o electrónica) y número de teléfono del Usuario, una descripción del contenido ilícito que comunica y una declaración expresa de la exactitud de lo comunicado, sin perjuicio de que ello no suponga el conocimiento por SdPP de los contenidos comunicados.</p>
	<p>Asimismo, SdPP no se hace responsable por los daños producidos por la ilicitud, calidad o desactualización de los contenidos o servicios de los sitios web enlazados, ni por cualquier otro daño que no sea directamente imputable a SdPP, salvo lo previsto en el mencionado artículo 17 de la Ley de Servicios de la Sociedad de la Información.</p>
	<h3><strong>Asistente virtual</strong></h3>
	<p>El Asistente Virtual del portal de Bizum, “Bizzi”, es un sistema que facilita ayuda a los Usuarios de este sitio web mediante un procedimiento de preguntas y respuestas y proporciona la información solicitada mediante una respuesta directa o bien, ofreciendo a los Usuarios la posibilidad de que desde SdPP, como titular del portal, se pongan en contacto con ellos.</p>
	<p>Los Usuarios se comprometen a realizar un uso adecuado de este servicio, conforme a las condiciones de uso aquí descritas, de modo que no insertarán a través del mismo contenidos inadecuados u ofensivos.</p>
	<p>En caso de que exista contradicción entre la información facilitada mediante el Asistente Virtual “Bizzi” y la mostrada en el portal web de Bizum, prevalecerá en todo caso la información proporcionada por dicha página web.</p>
	<h3><strong>Datos de carácter personal</strong></h3>
	<p>SdPP cumple íntegramente con la legislación vigente en materia de protección de datos de carácter personal y con los compromisos de confidencialidad propios de su actividad. SdPP ha adoptado las medidas técnicas necesarias para mantener el nivel de seguridad requerido, según la naturaleza de los datos personales tratados y las circunstancias del tratamiento, con el objeto de evitar, en la medida de lo posible y siempre según el estado de la técnica, su alteración, pérdida, tratamiento o acceso no autorizado.</p>
	<p>Le informamos que los datos identificativos que pueda aportarnos al utilizar el canal de comunicación “Contacto” de la URL <a href="https://www.bizum.es">https://www.bizum.es</a> o el canal del Asistente Virtual “Bizzi”, quedarán incluidos en un fichero titularidad de Sociedad de Procedimientos de Pago, S.L. (domicilio social: Francisco Sancha, 12 -28034 Madrid), con la finalidad de dar una respuesta adecuada a la solicitud de información realizada mediante el portal de Bizum. Le rogamos que en el contenido de la consulta o mensaje remitido a través del canal de comunicación «Contacto» o a través del canal del Asistente Virtual “Bizzi” se limite a incluir la información estrictamente necesaria.</p>
	<p>Le informamos que puede ejercitar sus derechos de acceso, rectificación, cancelación y oposición al tratamiento de sus datos de contacto, ante Sociedad de Procedimientos de Pago, S.L., con domicilio social situado en la calle Francisco Sancha, 12 – 28034 Madrid, indicando en su solicitud los siguientes datos: nombre, apellidos, domicilio a efectos de notificaciones y fotocopia del D.N.I. o de cualquier otro documento válido en Derecho que acredite su identidad y petición.</p>
	<p>Algunas de las páginas del portal de Bizum disponen de “cookies”, que son pequeños ficheros de datos que se generan en el ordenador del Usuario y que permiten a nuestros sistemas recordar algunas características o preferencias de navegación del Usuario. Para más información puede leer nuestra <a href="https://bizum.es/politica-de-privacidad/">Política de Cookies.</a></p>
	<h3><strong>Propiedad intelectual e industrial</strong></h3>
	<p>El portal de Bizum, las páginas que comprende y la información o elementos contenidos en las mismas, incluyen textos, documentos, fotografías, dibujos, representaciones gráficas, bases de datos, programas informáticos, así como logotipos, marcas, nombres comerciales, u otros signos distintivos, protegidos por derechos de propiedad intelectual o industrial, de los que SdPP es titular o legítima licenciataria.</p>
	<p>SdPP no otorga garantía alguna sobre la licitud y legalidad de la información o elementos contenidos en las páginas del portal de Bizum cuando la titularidad de los mismos no corresponda a SdPP.</p>
	<h3><strong>Usos prohibidos y permitidos</strong></h3>
	<p>Queda prohibida cualquier modalidad de explotación, incluyendo todo tipo de reproducción, distribución, cesión a terceros, comunicación pública y transformación, mediante cualquier tipo de soporte y medio, de las obras antes referidas, creaciones y signos distintivos sin autorización previa y expresa de sus respectivos titulares. El incumplimiento de esta prohibición podrá constituir infracción sancionable por la legislación vigente.</p>
	<p>Queda prohibido, salvo en los casos que expresamente lo autorice SdPP, establecer enlaces, hipervínculos o links, desde portales o sitios web de terceros a páginas web de SdPP distintas de la página principal del portal de Bizum, accesible en la dirección URL <u><a href="https://www.bizum.es/">https://www.bizum.es</a></u>, o la que le sustituya en el futuro, así como presentar las páginas web de SdPP o la información contenida en ellas bajo frames o marcos, signos distintivos, marcas o denominaciones sociales o comerciales de otra persona, empresa o entidad.</p>
	<h3><strong>Responsabilidades</strong></h3>
	<p>SdPP no garantiza el acceso continuado, ni la correcta visualización, descarga o utilidad de los elementos e información contenidos en las páginas del portal de Bizum, que pueden verse impedidos, dificultados o interrumpidos por factores o circunstancias fuera de su control.</p>
	<p>SdPP no es responsable de la información y demás contenidos integrados en espacios o páginas web de terceros desde los que se acceda mediante enlaces, hipervínculos o links al portal de Bizum o a cualquier de sus páginas web, ni de la información y contenidos de cualquier página web de terceros que se presente bajo la apariencia o signos distintivos de SdPP, salvo autorización expresa de esta última.</p>
	<p>SdPP no asume responsabilidad alguna con relación a la información, contenidos de todo tipo, productos y servicios ofertados a través del portal de Bizum por terceras personas o entidades.</p>
	<p>SdPP no es responsable de las inexactitudes que pudiera contener la información proporcionada a través del Asistente Virtual “Bizzi”.</p>
	<p>SdPP tampoco asume responsabilidad alguna por daños, perjuicios, pérdidas, reclamaciones o gastos, producidos por:</p>
	<p>1.Interferencias, interrupciones, fallos, omisiones, averías telefónicas, retrasos, bloqueos o desconexiones en el funcionamiento del sistema electrónico, motivadas por deficiencias, sobrecargas y errores en las líneas y redes de telecomunicaciones, o por cualquier otra causa ajena al control de SdPP.<br />
	2.Intromisiones ilegítimas mediante el uso de programas malignos de cualquier tipo y a través de cualquier medio de comunicación, tales como virus informáticos o cualesquiera otros.<br />
	3.uso indebido o inadecuado del portal de Bizum.<br />
	4.errores de seguridad o navegación producidos por un mal funcionamiento del navegador o por el uso de versiones no actualizadas del mismo.</p>
	<h3><strong>Ley aplicable</strong></h3>
	<p>El contenido de este Aviso Legal se regirá conforme a la ley común española.<br />
	Las partes someten la resolución de cualquier litigio o reclamación relacionado con el sitio web de Bizum a los Juzgados y Tribunales del domicilio del consumidor.</p>
</div>
	`;

	return new Response(200, {}, response);
}
