import { Response } from 'miragejs';

export default async (schema, request) => {
	if (!request?.params?.productId) {
		return new Response(400, {}, {});
	}

	const response = {
		data: schema.products.find(request.params.productId).products.models,
	};

	const handler = () => {
		return new Response(200, {}, response);
	};

	return handler();
};
