import { Response } from 'miragejs';

const baseURL =
	window.VUE_APP_CONFIG?.endpoint || process.env.VUE_APP_ENDPOINT || 'https://api.gruposmartfi.es';

export default async function() {
	const response = {
		url: `${baseURL}/bizum/terms`,
		version: '1.0.0',
	};

	return new Response(200, {}, response);
}
