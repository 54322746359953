import { Response } from 'miragejs';

export default (schema, request) => {
	const session = schema.sessions.find(request.requestHeaders.uuid);
	const { contracts, connectedContract } = session.user;
	const response = {
		connectedContract,
		contracts: contracts.models,
	};

	return new Response(200, {}, response);
};
