import { render, staticRenderFns } from "./c-fullscreen-spinner.vue?vue&type=template&id=0045466c&scoped=true&functional=true&"
import script from "./c-fullscreen-spinner.vue?vue&type=script&lang=js&"
export * from "./c-fullscreen-spinner.vue?vue&type=script&lang=js&"
import style0 from "./c-fullscreen-spinner.vue?vue&type=style&index=0&id=0045466c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  "0045466c",
  null
  
)

component.options.__file = "c-fullscreen-spinner.vue"
export default component.exports