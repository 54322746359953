export default {
	/**
	 * @typedef {Object} credential
	 * @property {String} documentId
	 * @property {String} password
	 * @property {('BC'|'BF')} companyId
	 * @property {String} channel
	 * @property {String} deviceId
	 */

	/**
	 * Authenticate the payload generated by the encryption of
	 * the credentials with the symmetric key and seed obtained
	 * after register the public key.
	 * @param {credential} payload
	 */
	request: {
		// url: '/login',
		url: '/login',
		method: 'POST',
	},
};
