import { Response } from 'miragejs';

export default (schema, request) => {
	const { contractId } = request.params;
	const { user } = schema.sessions.find(request.requestHeaders.uuid);
	const contract = user.contracts.models.find(({ id }) => id === contractId);
	let response = {};

	if (contract?.id) {
		response = {
			id: contract.id,
			description: contract.description,
			type: contract.type,
		};

		user.update({
			connectedContract: response,
		});
	}

	return new Response(200, {}, response);
};
