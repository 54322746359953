import authn from '@kukenan/store/modules/authn/m-authn';

const { env } = window.VUE_APP_CONFIG;
const PROD_MODE = env === 'prd';

export default {
	app: {
		namespaced: true,

		state: {
			companyId: 'SM',
			onboardingUrl: 'https://digitalsmartfi.com/',
			telephone: '+34 900 00 88 11',
			email: 'cat@smartfi.es',
			cookiesDetail: '#',
			measurementId: 'G-DG08H76NXM',
			legalIdentity: 'Smartfi',
			liveagent: PROD_MODE
				? {
						deployment: 'c.la3-c2-fra',
						deploymentId: '57209000000kc42',
						orgId: '00D09000007T9DK',
						chatURL: '',
						buttonId: '57309000000kckm',
				  }
				: {
						deployment: 'c.la2-c1cs-fra',
						deploymentId: '5729E000000CbNM',
						orgId: '00D9E0000004n9D',
						chatURL: '',
						buttonId: '5739E0000008QB8',
				  },
		},
	},
	authn,
};
