export default function(server) {
	server.createList('communication', 3, 'preLogin');
	server.createList('communication', 2, 'postLogin');

	server.create('user', 'firstTime', {
		documentId: '12345678A',
		products: [
			...server.createList('product', 1, 'account'),
			...server.createList('product', 1, 'debitCard'),
		],
	});

	server.create('user', 'sca', {
		documentId: '12345678B',
		products: [
			...server.createList('product', 1, 'account'),
			...server.createList('product', 3, 'creditCard'),
		],
	});

	server.create('user', {
		documentId: '12345678C',
		products: [
			...server.createList('product', 3, 'account'),
			...server.createList('product', 2, 'debitCard'),
			...server.createList('product', 1, 'creditCard'),
		],
	});

	server.create('user', {
		documentId: '12345678D',
		products: [
			...server.createList('product', 1, 'checkingAccount'),
			...server.createList('product', 1, 'account'),
			...server.createList('product', 1, 'juniorAccount'),
			...server.createList('product', 1, 'supportAccount'),
			...server.createList('product', 1, 'debitCard'),
			...server.createList('product', 1, 'creditCard'),
			...server.createList('product', 2, 'credit'),
			...server.createList('product', 1, 'termDeposit'),
			...server.createList('product', 1, 'demandDeposit'),
			...server.createList('product', 1, 'pensionPlan'),
			...server.createList('product', 1, 'loanFixed'),
			...server.createList('product', 1, 'securitiesAccount'),
			...server.createList('product', 1, 'advisedFund'),
			...server.createList('product', 1, 'delegatedFund'),
			...server.createList('product', 1, 'smartfiEquities'),
			...server.createList('product', 1, 'pendingMovements'),
			...server.createList('product', 1, 'endorsement'),
			...server.createList('product', 1, 'endorsementLine'),
			...server.createList('product', 1, 'vipAccount'),
			...server.createList('product', 2, 'vipDebitCard'),
			...server.createList('product', 1, 'vipCreditCard'),
			...server.createList('product', 1, 'vipDeposit'),
			...server.createList('product', 2, 'managedPortfolio', {
				products: [
					...server.createList('product', 1, 'checkingAccount'),
					...server.createList('product', 1, 'creditCard'),
					...server.createList('product', 1, 'delegatedFund'),
				],
			}),
		],
		transfers: [
			...server.createList('transfer', 10),
			...server.createList('transfer', 10, 'favorite'),
			...server.createList('transfer', 10, 'scheduled'),
			...server.createList('transfer', 10, 'periodic'),
			...server.createList('transfer', 1, 'unknown'),
		],
		contracts: [
			...server.createList('contract', 1, 'owner'),
			...server.createList('contract', 2, 'user'),
		],
		signatures: [
			...server.createList('signature', 3, 'pending'),
			...server.createList('signature', 3, 'signed'),
			...server.createList('signature', 1, 'unknown'),
		],
	});
	server.create('user', {
		documentId: 'A03',
		products: [
			...server.createList('product', 1, 'checkingAccount'),
			...server.createList('product', 1, 'account'),
			...server.createList('product', 1, 'juniorAccount'),
			...server.createList('product', 1, 'supportAccount'),
			...server.createList('product', 1, 'debitCard'),
			...server.createList('product', 1, 'creditCard'),
			...server.createList('product', 2, 'credit'),
			...server.createList('product', 1, 'termDeposit'),
			...server.createList('product', 1, 'demandDeposit'),
			...server.createList('product', 1, 'pensionPlan'),
			...server.createList('product', 1, 'loanFixed'),
			...server.createList('product', 1, 'securitiesAccount'),
			...server.createList('product', 1, 'advisedFund'),
			...server.createList('product', 1, 'delegatedFund'),
			...server.createList('product', 1, 'smartfiEquities'),
			...server.createList('product', 1, 'pendingMovements'),
			...server.createList('product', 1, 'endorsement'),
			...server.createList('product', 1, 'endorsementLine'),
			...server.createList('product', 1, 'vipAccount'),
			...server.createList('product', 2, 'vipDebitCard'),
			...server.createList('product', 1, 'vipCreditCard'),
			...server.createList('product', 1, 'vipDeposit'),
			...server.createList('product', 2, 'managedPortfolio', {
				products: [
					...server.createList('product', 1, 'checkingAccount'),
					...server.createList('product', 1, 'creditCard'),
					...server.createList('product', 1, 'delegatedFund'),
				],
			}),
			...server.createList('product', 1, 'etherium'),
			...server.createList('product', 1, 'bitcoin'),
			...server.createList('product', 1, 'xrp'),
		],
		transfers: [
			...server.createList('transfer', 10),
			...server.createList('transfer', 10, 'favorite'),
			...server.createList('transfer', 10, 'scheduled'),
			...server.createList('transfer', 10, 'periodic'),
			...server.createList('transfer', 1, 'unknown'),
		],
		contracts: [
			...server.createList('contract', 1, 'owner'),
			...server.createList('contract', 2, 'user'),
		],
		signatures: [
			...server.createList('signature', 3, 'pending'),
			...server.createList('signature', 3, 'signed'),
			...server.createList('signature', 1, 'unknown'),
		],
	});

	server.create('user', {
		documentId: '12345678E',
		products: [
			...server.createList('product', 1, 'checkingAccount'),
			...server.createList('product', 2, 'account'),
			...server.createList('product', 2, 'creditCard'),
			...server.createList('product', 2, 'pensionPlan'),
			...server.createList('product', 2, 'loanFixed'),
			...server.createList('product', 2, 'loanVar'),
			...server.createList('product', 2, 'securitiesAccount'),
			...server.createList('product', 2, 'advisedFund'),
		],
	});

	// server.create('user', { documentId: 'A05', empty: true });
	server.create('user', { documentId: '12345678F', empty: true });

	server.create('user', {
		// documentId: 'D00',
		documentId: '12345678G',
		products: [
			...server.createList('product', 1, 'account'),
			...server.createList('product', 1, 'termDeposit'),
			...server.createList('product', 1, 'demandDeposit'),
		],
	});

	server.create('user', {
		// documentId: 'D01',
		documentId: '12345678H',
		products: [
			...server.createList('product', 1, 'account'),
			...server.createList('product', 2, 'termDeposit'),
			...server.createList('product', 2, 'demandDeposit'),
		],
		bizum: true,
	});

	server.create('user', {
		// documentId: 'D02',
		documentId: '12345678I',
		products: [
			...server.createList('product', 1, 'account'),
			...server.createList('product', 1, 'termDeposit'),
		],
	});

	server.create('user', {
		// documentId: 'D03',
		documentId: '12345678J',
		products: [
			...server.createList('product', 1, 'account'),
			...server.createList('product', 4, 'currencyAccount'),
			...server.createList('product', 1, 'demandDeposit'),
			...server.createList('product', 4, 'currencyDeposit'),
			...server.createList('product', 1, 'managedPortfolio', {
				products: [
					...server.createList('product', 1, 'checkingAccount'),
					...server.createList('product', 2, 'currencyAccount'),
					...server.createList('product', 2, 'currencyDeposit'),
				],
			}),
		],
	});

	server.create('user', {
		// documentId: 'D04',
		documentId: '12345678K',
		products: [
			...server.createList('product', 1, 'account'),
			...server.createList('product', 2, 'termDeposit'),
		],
	});

	server.create('user', {
		// documentId: 'D05',
		documentId: '12345678L',
		products: [
			...server.createList('product', 1, 'account'),
			...server.createList('product', 2, 'demandDeposit'),
		],
	});

	server.create('user', {
		// documentId: 'P00',
		documentId: '12345678M',
		products: [
			...server.createList('product', 1, 'account'),
			...server.createList('product', 1, 'pendingMovements'),
		],
	});

	// server.create('user', { documentId: 'B00' }, 'blocked');
	server.create('user', { documentId: '12345678N' }, 'blocked');

	server.create('agent', {
		userId: '007',
		companies: ['SM'],
	});
}
