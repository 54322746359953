import { Response } from 'miragejs';
import { decryptAES } from '@modules/secure/cypher';
import SignedOperation from './mock-signed-operation';

export default async (schema, request) => {
	const { payload } = JSON.parse(request.requestBody);
	const { seed, key, user } = schema.sessions.find(request.requestHeaders.uuid);
	const data = await decryptAES({
		seed,
		key,
		data: payload,
	});
	const { password } = data;
	const handler = () => {
		user.update({ password });
		user.update({ passwordChange: false });
		return new Response(204);
	};

	return new SignedOperation(schema, handler);
};
