import { Response } from 'miragejs';

export default async (schema, request) => {
	const { user } = schema.sessions.find(request.requestHeaders.uuid);

	if (user.bizum) {
		return new Response(204, {});
	}

	return new Response(404, {});
};
