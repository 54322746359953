import { Factory, trait } from 'miragejs';
import faker from 'faker/locale/es';

export default Factory.extend({
	owner: trait({
		id: () => faker.random.uuid(),
		description: () => `${faker.name.firstName()} ${faker.name.lastName()}`,
		type: 'owner',
	}),
	user: trait({
		id: () => faker.random.uuid(),
		description: () => `${faker.name.firstName()} ${faker.name.lastName()}`,
		type: 'user',
	}),
});
