const def = {
	'01': 'account',
	'02': 'card',
	'03': 'deposit',
	'04': 'fund',
	'05': 'pension-plan',
	'06': 'equities',
	'07': 'loan',
	'08': 'endorsement',
	'09': 'tpv',
	'10': 'credit',
	'11': 'subscription',
	'12': 'discount-line',
	'13': 'assurance',
	'14': 'service',
	'15': 'managed-portfolio',
	'16': 'pending-movements',
	'17': 'crypto-account',
};

export const typesById = def;
export const typesByTitle = Object.fromEntries(Object.entries(def).map((a) => a.reverse()));
