<template>
	<div id="vuesoma">
		<img
			class="printable-logo media-screen-hide"
			src="@local-assets/img/logo-horizontal-alt.svg"
			alt=""
			aria-hidden="true"
		/>
		<span class="printable-margin-text media-screen-hide" aria-hidden="true">
			{{ legalIdentity }}
		</span>
		<router-view :inert="inert" />
		<w-notification />
		<w-modal />
		<router-view name="modal" />
		<c-fullscreen-spinner v-if="status" data-testid="spinner" />
	</div>
</template>

<script>
import { mapState } from 'vuex';
import CFullscreenSpinner from '@components/c-fullscreen-spinner';
import WModal from '@widgets/w-modal';
import WNotification from '@widgets/w-notification';
import mq from '@utils/matchMedia';
import { onPWA } from '@theme';
import { importLocale } from '@locales/setup';

export default {
	name: 'app',

	components: {
		CFullscreenSpinner,
		WNotification,
		WModal,
	},

	data() {
		return {
			expiredSessionTimeOut: 0,
		};
	},

	computed: {
		inert({
			$store: {
				getters: {
					'modal/lastOpened': lastModalOpened = false,
					'dialog/lastOpened': lastDialogOpened = false,
				},
			},
		}) {
			return Boolean(lastModalOpened || lastDialogOpened);
		},

		isPWA: mq(onPWA),

		...mapState('authn', ['loggedIn']),
		...mapState('session', ['theme', 'lang']),
		...mapState('app', ['legalIdentity']),
		...mapState('loading', ['status']),
	},

	watch: {
		isPWA(value) {
			/* istanbul ignore else */
			if (value) {
				document.addEventListener(
					'contextmenu',
					(e) => {
						/* istanbul ignore next */
						if (e.target.tagName.toLowerCase() !== 'input') {
							e.preventDefault();
						}
					},
					false
				);
			}
		},

		loggedIn(value) {
			if (!value) {
				clearInterval(this.expiredSessionTimeOut);
				/* istanbul ignore else */
				if (this.$route.name !== 'login') {
					this.$router.push({ name: 'login' });
				}
			} else {
				const oneSecond = 1000;
				this.expiredSessionTimeOut = setInterval(() => {
					const minutesFromLastRequest =
						(new Date() - this.$store.state.service.lastRequestTimestamp) / 1000 / 60;

					/* istanbul ignore else */
					if (minutesFromLastRequest > 10) {
						this.$store.dispatch('authn/passiveLogout');
					}
				}, oneSecond);
			}
		},

		theme: {
			immediate: true,
			handler(value) {
				document.documentElement.dataset.theme = value;
			},
		},

		lang(value) {
			importLocale(value);
		},
	},

	created() {
		this.$router.afterEach((to, from) => {
			this.$store.dispatch('bugsnag/log', {
				type: 'navigation',
				title: 'Router push',
				from: from.path,
				to: to.path,
			});

			this.$nextTick(() => {
				window.dispatchEvent(
					new CustomEvent('bridge-router-nav', { detail: { name: to.name, fullPath: to.fullPath } })
				);
			});
		});
	},
};
</script>

<style lang="scss" scoped>
#vuesoma {
	height: 100%;
	display: flex;
}

[inert] {
	overflow: hidden;
}
</style>

<!-- This should generally be the only global CSS in the app. -->
<style lang="scss">
@import '~@design/index.scss';

body {
	@extend %typo-m-book;
}

.v__internal-a11y-hide {
	position: absolute;
	clip: rect(1px, 1px, 1px, 1px);
	background: 0 0;
	color: transparent;
	width: 1px;
	height: 1px;
	overflow: hidden;
}

:not([tabindex='-1']):focus:focus-visible::before {
	content: '';
	display: block;
	position: absolute;
	border-radius: inherit;
	top: calc(-1 * var(--focus-ring-padding, 0px) - 1px);
	bottom: calc(-1 * var(--focus-ring-padding, 0px) - 1px);
	left: calc(-1 * var(--focus-ring-padding, 0px) - 1px);
	right: calc(-1 * var(--focus-ring-padding, 0px) - 1px);
	border: 1px solid RGB(var(--color-focus-ring));
	box-shadow: 0 0 0 1px RGB(var(--color-focus-ring));
}

@media (pointer: fine), (hover) {
	::-webkit-scrollbar {
		width: 16px;
	}

	::-webkit-scrollbar-thumb {
		border-radius: 10px;
		box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.2),
			inset 0 0 0 30px RGBA(var(--color-text-primary), 0.3);
		border: 4px solid transparent;
	}

	::-webkit-scrollbar-thumb:window-inactive {
		visibility: hidden;
	}
}

.media-screen-hide {
	display: none !important;
}

@media print {
	body {
		--color-gradient-header: none;
		background: white !important;
	}

	.media-screen-hide {
		display: block !important;
	}

	.media-print-hide {
		display: none !important;
	}

	.printable-margin-text {
		position: fixed;
		z-index: 10;
		left: 0;
		top: 0;
		bottom: 0;
		text-align: center;
		writing-mode: vertical-rl;
		transform: rotate(-180deg);
		color: RGB(var(--color-text-primary));
		opacity: 0.6;
	}

	.printable-logo {
		position: fixed;
		top: 0;
		left: 0;
		z-index: 10;
		height: 32px;
	}
}
</style>
